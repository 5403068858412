import Vue from 'vue'
import Router from 'vue-router'
import VueKinesis from 'vue-kinesis'

Vue.use(Router)

Vue.use(VueKinesis);

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/about',
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('../pages/AboutUs.vue')
        },
        {
            path: '/business',
            name: 'business',
            component: () => import('../pages/Business.vue')
        },
        {
            path: '/customer',
            name: 'customer',
            component: () => import('../pages/Customer.vue')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../pages/GetInTouch')
        },
    ]
})
