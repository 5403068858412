<template>
  <v-app class="app">
    <div class="app-inner">
      <div class="md:tw-hidden tw-flex">
        <v-app-bar elevation="0" color="transparent" app>
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        </v-app-bar>

        <v-navigation-drawer style="position:fixed; top:0; left:0; overflow-y:scroll;" v-model="drawer" absolute app
                             temporary>
          <div class="tw-flex-col tw-justify-center tw-text-center tw-h-screen tw-pt-20">
            <div v-for="item in menuItems" :key="item.path" class="hover-container tw-mb-8">
              <a
                  v-if="item.download"
                  class="menu-title hover tw-flex tw-items-center"
                  style="text-decoration: none; color: inherit;"
                  :href="item.path"
                  download>
                {{item.name}}
                <img class="tw-ml-1 tw-w-6 tw-h-6 tw-opacity-40" style="filter: invert(1)" src="@/assets/download.webp" alt="download icon"/>
              </a>
              <router-link
                  v-else
                  style="text-decoration: none; color: inherit;"
                  :class="selected(item.path) ? 'menu-title-selected' : '' + ' menu-title hover'"
                  :to="item.path">
                {{ item.name }}
              </router-link>
            </div>
          </div>
        </v-navigation-drawer>
      </div>

      <v-main class="tw-p-0 tw-self-center">
        <div class="tw-hidden md:tw-flex tw-justify-center tw-space-x-16 tw-w-full tw-my-10">
          <div v-for="item in menuItems" :key="item.path" class="hover-container">
            <a
                v-if="item.download"
                class="menu-title hover tw-flex tw-items-center"
                style="text-decoration: none; color: inherit;"
                :href="item.path"
                download>
              {{item.name}}
              <img class="tw-ml-1 tw-w-6 tw-h-6 tw-opacity-40" style="filter: invert(1)" src="@/assets/download.webp" alt="download icon"/>
            </a>
            <router-link
                v-else
                style="text-decoration: none; color: inherit;"
                :class="selected(item.path) ? 'menu-title-selected' : '' + ' menu-title hover'"
                :to="item.path">
              {{ item.name }}
            </router-link>
          </div>
        </div>

        <router-view class="tw-mt-20 md:tw-mt-10"/>
      </v-main>
    </div>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  metaInfo: {
    title: 'membo',
  },

  components: {},

  data: () => ({
    drawer: false,
    menuItems: [
      {
        path: "/about",
        name: "About us",
      },
      {
        path: "/business",
        name: "For business",
      },
      {
        path: "/customer",
        name: "For customers",
      },
      {
        path: "/contact",
        name: "Contact",
      },
    ]
  }),

  methods: {
    selected(path) {
      return window.location.path === path;
    }
  }
};
</script>

<style scoped>
.app {
  background-color: #000000;
}

.app-inner {
  height: 100%;
}
</style>
